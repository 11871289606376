import React, { useEffect, useState,  } from "react";
import Papa from "papaparse";
import { auth, store } from "../firebase"
const DownloadAll = () => {
const [customers, setCustomers] = useState([]);

    useEffect(() => {
        const unsubscribe = store.collection('customers')
          .onSnapshot(snapshot => {
            if (snapshot.size) {
              let newCustomers=[]
              snapshot.forEach((doc) => {
                // console.log(doc.id)
                newCustomers.push({
                  id: doc.id,
                  ...doc.data()
                })
             })
             console.log(newCustomers)
         
            setCustomers(newCustomers)
            } else {
              console.log("query empty")
            }
          })
      return () => {
          unsubscribe()
        }
      }, [])

  

         //// For CVS of wrong phones::::
  const download = (data) =>{
  //  debugger
    const blob = new Blob([data], { type: `text/csv` });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("dowload", "wrongPhones.cvs");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

      const parseFunctionForDownloadCSV = () => {
        const configObj = {
          quotes: false, //or array of booleans
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ",",
          header: true,
          newline: "\r\n",
          skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
          columns: null, //or array of strings
          completed: () => {
            console.log("this yeyyy");
          },
        };
    
        const fixedData = customers.map((item) => {
          const arrItem = {};
    
          /* Iterate keys of item */
          for (const key in item) {
            // console.log("key", key);
            arrItem[key] = item[key];
          }
          // console.log(arrItem)
          /* Return arrItem */
          return arrItem;
        });
    
    
        const newFile = Papa.unparse(fixedData, configObj);
       
          download(newFile)
        // console.log("finish", newFile);
      };








    return (
        <div>
           
            <button onClick={parseFunctionForDownloadCSV}>
            Download All customers
          </button>
        </div>
    );
}

export default DownloadAll;
