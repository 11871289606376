import React from 'react';

const Forecast = () => {
    return (
        <div>
            Forecast
        </div>
    );
}

export default Forecast;
