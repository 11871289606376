import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { useStore } from "../contexts/StoreContext"
import { Button } from "@material-ui/core";

export default function MassUpload() {
  const [file, setFile] = useState("");
  const { arrayBatch } = useStore()

  const configObj = {
    header: true,
    skipEmptyLines: true,
    encoding: "utf-8",

    complete: function (results) {
      setFile(results.data);
    },
  };

  async function sendToDatabase() {
    console.log(file);
    // console.log({app});
    try {
      await arrayBatch(file);
    } catch (error) {
      alert(error.message);
    }
  }

  useEffect(() => {
    console.log(file);
  }, [file]);

  const parseFunction = (file) => {
    const newFile = Papa.parse(file, configObj);
  };

  return (
    <div>
      <h1 style={{ marginTop: "100px" }}> Products upload</h1>
      <div>
        <div>
          <div>
            <input
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",

                transform: "translate(-50%, -50%)",
              }}
              type="file"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              onChange={(e) => {
                parseFunction(e.target.files[0]);
                // setFile(e.target.files[0]);
                // setFileName(e.target.files[0].name);
              }}
            />

            <Button
              style={{
                position: "fixed",
                top: "70%",
                left: "40%",

                transform: "translate(-50%, -50%)",
                background: "#5dbcd2",
                color: "black",
                "&:hover": {
                  color: "white",
                  background: "#5dbcd2",
                  textDecoration: "none",
                },
              }}
              variant="contained"
              color="primary"
              onClick={sendToDatabase}
            >
              To DataBase
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

