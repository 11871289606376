import { withRouter, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from "@material-ui/icons/Search";
import Map from './Map';
import { useStore } from "../contexts/StoreContext"
import { useAuth } from "../contexts/AuthContext"
import PacmanLoader from "react-spinners/PacmanLoader";
import TextField from '@material-ui/core/TextField';

/* eslint-disable no-undef */



const MapWrapper = () => {
  const reps=[
    "All",
    "blanca@cr-beauty.com",
     "c.snowden@cr-beauty.com",
     "j.zavala@cr-beauty.com",
     "luismiguel@cr-beauty.com",
     "nestor@cr-beauty.com",
     "vielka@cr-beauty.com",
     "y.alcantara@cr-beauty.com",
     "nelsonrzavala@gmail.com"
  ]

  const { getCustomers } = useStore()
  const {  currentUser } = useAuth()
  //////
  const [CurrentLoc, setCurrentLoc] = useState({})
  const [search, setSearch] = useState("");
  const [customers, setCustomers] = useState([]);
  const [fetchData, setFetchData] = useState([]);
  const [select, setSelect] = useState(currentUser.email);
  const [dimesinoW, setDimensionW] = useState({width: window.innerWidth, height: window.innerHeight});

  const filteredCustomers = (newCustomers) => {
    
    let x = newCustomers

    if (select==='All') {
        x = newCustomers
      } else {
       x= newCustomers.filter(
          (customer) =>
            customer["Rep"]===select 
            
        )
       
      }

      // console.log("x here", x)


    if (search === "") {
      setCustomers(x);
    } else {
      setCustomers(
        x.filter(
          (customer) =>
            customer["Name"].toLowerCase().includes(search.toLowerCase()) 
            ||
            customer["Address"]
              .toLowerCase()
              .includes(search.toLowerCase()) 
              ||
              customer["Zipcode"]
                .toLowerCase()
                .includes(search.toLowerCase()) 
              ||
              customer["Mobile phone"]
                .toLowerCase()
                .includes(search.toLowerCase()) 
              ||
              customer["City"]
                .toLowerCase()
                .includes(search.toLowerCase())
        )
      );
    }
  };

  const handleChange = (event) => {
    setSelect(event.target.value);
    console.log("rep", select)
  };




  const miss = err => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };
  
  const success = (position) => {
    let pos= position.coords;
    console.log(position.coords)
    setCurrentLoc({
      latitude: pos.latitude,
      longitude: pos.longitude,
    })
    console.log(CurrentLoc)
  }



  useEffect(() => {
    let options = {
      enableHighAccuracy: true
    };
    navigator.geolocation.getCurrentPosition(success, miss, options);
  }, [])




  useEffect(() => {
    getCustomers()
 .then((snapshot) => {
      const newCustomers = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const sortedCustomers = newCustomers.sort(function (a, b) {
        if (a["Name"] < b["Name"]) {
          return -1;
        }
        if (a["Name"] > b["Name"]) {
          return 1;
        }
        return 0;
      });
      console.log(sortedCustomers);
  
     
      setFetchData(sortedCustomers);
    });
  }, []);

  const updateWidthAndHeight = () => {
    setDimensionW({width: window.innerWidth, height: window.innerHeight});
  };


  useEffect(() => {
    // setTimeout(function () {
    //   setOpen(true);
    // }, 5000);
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => filteredCustomers(fetchData), [search, select, fetchData]);

  //// helper funcs::::

  const updateSearch = (e) => {
    setSearch(e.target.value);
  };

 
  //////////////////////

  return (
 
      <div >
        {/* <div >
          <SearchIcon />
        </div> */}

     <div style={{position: "relative"}}>
        
       {currentUser.uid==="OdATi23YK3QB0HqgOXjkZ7PFWij2" ? 
                <TextField
                        style={{   position: "fixed",
                                  top: "15%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  backgroundColor: "white",
                                  opacity: "70%",
                                  zIndex: 100
                              }}
                        id="filter"
                        size= "small"
                        variant="outlined"
                        select
                        value={select}
                        onChange={handleChange}>
              {reps.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
               </TextField>
               : null}

                   <TextField
                    size= "small"
                      //  variant="filled"
                      placeholder="Customer Search"
                    
                      
                      onChange={updateSearch}
                            style={{
                                  position: "fixed",
                                  top: "25%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  backgroundColor: "white",
                                  opacity: "70%",
                                  zIndex: 100
                                  }}
                      startAdornment={
                        <InputAdornment position="start">
                        <SearchIcon/>
                        </InputAdornment>
                      }
                    />
     </div>
          
   {
        fetchData.length<=0|| Object.keys(CurrentLoc).length === 0 ? 
        <PacmanLoader style={{color: "#182028"}}/>
        : 
        <Map customers={customers} location={CurrentLoc} size={dimesinoW}/>    
    }
      </div>

  );
};
export default withRouter(MapWrapper);
