/* eslint-disable no-undef */
import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { useStore } from "../contexts/StoreContext"
import { useHistory, Link } from "react-router-dom"
export default function Checkin() {

  const { getSingleCheckin, getSingleCustomer } = useStore()
  const {  currentUser } = useAuth()
  const [CurrentLoc, setCurrentLoc] = useState({})
  const [currentAddress, setCurrentAddress] = useState("")
  const [checkin, setCheckin] = useState("")
  const [customer, setCustomer] = useState("")
  const customerDocumentId = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1)
  console.log(customerDocumentId)



useEffect(() => {
  getSingleCheckin(customerDocumentId)
  
 .then((snapshot) => {
  // debugger
  const newCheckin = snapshot.data()
  
    let x = {"Name": newCheckin["Name"],
              "Address": newCheckin["Address"], 
              "Date": newCheckin["dateInput"],
              "Phone": newCheckin["Phone"], 
              "Mobile phone": newCheckin["Mobile phone"],
              "Type": newCheckin["type"],
              "Notes": newCheckin["notes"],
              "Sale Amount": newCheckin["saleAmount"],
              "Rep": newCheckin["Rep"],
               "TimeStamp": newCheckin["timestamp"],
               "Check In Location": newCheckin["currentLocation"],
               "Latitude": newCheckin["customerLatitude"],
               "Longitude": newCheckin["customerLongitude"]
              }

                if (newCheckin["Zipcode"]) {
                  x["Address"]= `${newCheckin["Address"]} ${newCheckin["City"]}, 
              ${newCheckin["State"]}, ${newCheckin["Zipcode"]}`
                }

            if (currentUser.uid==="OdATi23YK3QB0HqgOXjkZ7PFWij2") {
          
              setCheckin(x)
              // console.log(x)
           } else {
            delete x["TimeStamp"]
            delete x["Rep"]
            delete x["Check In Location"]
             setCheckin(x)
           }
 
    
        console.log("ceheck in", newCheckin["customerDocumentId"])

       getSingleCustomer(`${newCheckin["customerDocumentId"]}`)
   .then((snapshot) => {
    const newCustomer = snapshot.data()
    console.log(newCustomer)
  
       let obj= {"Name": newCustomer["Name"],
       "Address": newCustomer["Address"], 
       "Phone": newCustomer["Phone"], 
       "Mobile phone": newCustomer["Mobile phone"], ...newCustomer
     }
            
     if (currentUser.uid==="OdATi23YK3QB0HqgOXjkZ7PFWij2") {
            
      setCustomer(obj)
      console.log(obj)
   } else {
    
    delete obj["Rep"]
     setCustomer(obj)
   }
          console.log("customer", customer)
  
      });

    });
  }, []);

  return (
    <>
    {console.log(CurrentLoc, currentAddress)}
      <Card style={{ fontFamily: "Inter, Georgia,serif" }}>
        <Card.Body>
          <h2 className="text-center mb-4">Check In</h2>

              {Object.entries(checkin).map((property) => {
               /// Ternary to eliminate blank values and irrelevant data::
                return property[1] && property[0]!=="Longitude"
                && property[0]!=="Latitude" 
                // && property[0]!=="Rep"
                && property[0]!=="Customer ID"
                ? 
                  ///Ternary to add links:::
                  property[0]==="Address" ? 
                     <Form.Group id={property[0]}>
                      <Form.Label style={{color: "gray"}}>{property[0]}</Form.Label>
                        <br></br>
                        <Link 
                        target="_blank"
                        to={{
                          pathname: `https://www.google.com/maps/dir/Current+Location/${customer["Latitude"]},${customer["Longitude"]}`,  
                        }}
                        className={"nav.Link"}
                        >
                           {`${customer["Address"]} ${customer["City"]}, ${customer["State"]}, ${customer["Zipcode"]}`}
                        </Link>   
                     </Form.Group>  
                   : 
                        property[0]==="Mobile phone" || property[0]==="Phone"  
                        ? 
                              <Form.Group id={property[0]}>
                            <Form.Label style={{color: "gray"}}>{property[0]}</Form.Label>
                              <br></br>
                              <Link 
                              target="_blank"
                              to={{
                                pathname: `tel:+${customer[property[0]]}`,  
                              }}
                              className={"nav.Link"}
                              >
                                {property[1]}
                              </Link>   
                          </Form.Group> 
                        : 
                        property[0]==="Sale Amount"  
                        ? 
                        <Form.Group id={property[0]}>
                        <Form.Label style={{color: "gray"}}>{property[0]}</Form.Label>
                          <br></br>
                            <div >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(property[1])}</div>
                       </Form.Group> 
                        : 
                        property[0]==="Name"  ? 
                        <Form.Group id={property[0]}>
                        <Form.Label style={{color: "gray"}}>{property[0]}</Form.Label>
                          <br></br>
                          <Link 
                         
                          to={{
                            pathname: `/customer/${customer.id}`,  
                          }}
                          className={"nav.Link"}
                          >
                             {property[1]}
                          </Link>   
                       </Form.Group>  
                        : 
                   <Form.Group id={property[0]}>
                      <Form.Label style={{color: "gray"}}>{property[0]}</Form.Label>
                        <br></br>
                          <div >{property[1]}</div>
                     </Form.Group>  
                          //////////////////////
                : 
                  null 
              })}
        </Card.Body>
      </Card>
     {/* <Button onClick={geocode}></Button> */}
    </>
  )
}
