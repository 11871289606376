import React, { useState, useEffect } from "react"
import { Card, Button, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { useStore } from "../contexts/StoreContext"
import { useHistory } from "react-router-dom"
import moment from "moment";

export default function Dashboard() {
  
  const numberDate= moment().format("L")
  const stringDate= moment().format("LL")
  const dateStrArr= stringDate.split(" ")
  const year= parseInt(numberDate.slice(6,10))
  const month = parseInt(numberDate.slice(0,2))
  const { currentUser} = useAuth()
  const { getMonthlySales } = useStore()
  const history = useHistory()
  const [sale, setSale] = useState(0);
 
 
      useEffect(() => {
        getMonthlySales(month, year)
        .then((snapshot) => {
             const newCheckIns = snapshot.docs.map((doc) => ({
               id: doc.id,
               ...doc.data(),
             }));
            //  const sortedCheckIns = newCheckIns.sort(function (a, b) {
            //    if (a["customerName"] < b["customerName"]) {
            //      return -1;
            //    }
            //    if (a["customerName"] > b["customerName"]) {
            //      return 1;
            //    }
            //    return 0;
            //  });
            //  console.log(sortedCheckIns);
             let sum =0
             newCheckIns.map((checkin) => {
               sum+=checkin["saleAmount"]
             })
             setSale(sum)
            //  console.log(sale)

           });
      }, [])
 
 
  


  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Hello</h2>
          <h4 className="text-center mb-4">{currentUser.displayName}</h4>
          <h5 className="text-center mb-3">
            Sales total for {dateStrArr[0]}: <strong>{sale ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sale) : 0 } </strong>
          </h5>
         
        
        
        </Card.Body>
      </Card>
    
    </>
  )
}
