/* eslint-disable no-undef */
import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { useStore } from "../contexts/StoreContext"
import { useHistory, Link } from "react-router-dom"
import ModalCheckIns from '../components/ModalCheckIns';

export default function Customer() {

  const { getSingleCustomer } = useStore()
  const {  currentUser } = useAuth()
  const [CurrentLoc, setCurrentLoc] = useState({})
  const [currentAddress, setCurrentAddress] = useState("")
  const [customer, setCustomer] = useState("")
  const [modal, setModal] = useState(false)
  const customerDocumentId = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1)
  console.log(customerDocumentId)

    const handleClickOpen =()=>{
      setModal(!modal)
      console.log(modal)
    }

useEffect(() => {
  getSingleCustomer(customerDocumentId)
 .then((snapshot) => {
  const newCustomer = snapshot.data()
  // console.log(snapshot.data())
     let obj= {"Name": newCustomer["Name"],
     "Address": newCustomer["Address"], 
     "Phone": newCustomer["Phone"], 
     "Mobile phone": newCustomer["Mobile phone"], ...newCustomer
   }
          
   if (currentUser.uid==="OdATi23YK3QB0HqgOXjkZ7PFWij2") {
          
    setCustomer(obj)
    console.log(obj)
 } else {
  
  delete obj["Rep"]
   setCustomer(obj)
 }
        console.log("customer", customer)

    });
  }, []);
  

  
  
  return (
    <>
    {console.log(CurrentLoc, currentAddress)}
      <Card style={{ fontFamily: "Inter, Georgia,serif" }}>
        <ModalCheckIns open={modal} func={handleClickOpen}/>
        <Card.Body>
          <h2 className="text-center mb-4">Customer</h2>
          <Button variant="outline-primary"
          size="sm"
          onClick={handleClickOpen}
          
          style={{ 
          
            position: "fixed",
            left: "50%",
            /* bring your own prefixes */
            transform: "translate(-50%, -10%)"
            }}>Check Ins Log</Button>
                 <br/>
              {Object.entries(customer).map((property) => {
               /// Ternary to eliminate blank values and irrelevant data::
                return property[1] && property[0]!=="Longitude"
                && property[0]!=="Latitude" 
                && property[0]!=="Customer ID" && property[0]!=="State"
                && property[0]!=="City" && property[0]!=="Zipcode"
                && property[0]!=="id"
                ? 
                  ///Ternary to add links:::
                  property[0]==="Address" ? 

                   
                     <Form.Group id={property[0]}>
                      <Form.Label style={{color: "gray"}}>{property[0]}</Form.Label>
                        <br></br>
                        <Link 
                        target="_blank"
                        to={{
                          pathname: `https://www.google.com/maps/dir/Current+Location/${customer["Latitude"]},${customer["Longitude"]}`,  
                        }}
                        className={"nav.Link"}
                        >
                           {`${property[1]} ${customer["City"]}, ${customer["State"]}, ${customer["Zipcode"]}`}
                        </Link>   
                     </Form.Group>  
                   
                   
                   : 
                   
                   
                        property[0]==="Mobile phone" || property[0]==="Phone"  
                        ? 
                              <Form.Group id={property[0]}>
                            <Form.Label style={{color: "gray"}}>{property[0]}</Form.Label>
                              <br></br>
                              <Link 
                              target="_blank"
                              to={{
                                pathname: `tel:+1${property[1]}`,  
                              }}
                              className={"nav.Link"}
                              >
                                {property[1]}
                              </Link>   
                          </Form.Group> 
                        
                        
                        
                        : 
                   <Form.Group id={property[0]}>
                      <Form.Label style={{color: "gray"}}>{property[0]}</Form.Label>
                        <br></br>
                          <div >{property[1]}</div>
                     </Form.Group>  






                          //////////////////////
                : 
                  null 
              })}

    
   
        </Card.Body>
      </Card>


      <Link          
        to={{
          pathname:`/editcustomer/${customerDocumentId}`, 
          state: customer
            }}
          style={{ color: "white",         
          textDecoration: "none"}}               
      >
            <Button style={{ 
            minWidth: 320,
            position: "fixed",
            left: "50%",
            /* bring your own prefixes */
            transform: "translate(-50%, 5%)"
            }}>
              
                 Edit
            </Button>
      </Link> 
    </>
  )
}
