import { withRouter, Link, useHistory } from "react-router-dom";
import React, { useEffect, useState,  } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { auth, store } from "../firebase"



const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 40,
    width: "95%",
    position: "fixed",
    top: "50%",
    left: "50%",

    transform: "translate(-50%, -50%)",
  },
  container: {
    maxHeight: window.innerWidth < 500 ? 440 : 550,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),

    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    container: {
      maxHeight: 440,
    },
  },
  link: {
    margin: theme.spacing.unit,
    color: "black",
    "&:hover": {
      color: "#5dbcd2",

      textDecoration: "none",
    },
  },
}));

const CustomerList = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  //////
  const [search, setSearch] = useState("");
  const [customers, setCustomers] = useState([]);
  const [fetchData, setFetchData] = useState([]);
  const x = [
    { id: "Name", label: "Customer", minWidth: 170 },
    { id: "Address", label: "Address", minWidth: 170 },
    { id: "Phone", label: "Phone", minWidth: 170 },
    { id: "Mobile phone", label: "Mobile phone", minWidth: 170 },
    { id: "Nationality", label: "Nationality", minWidth: 150 },
    { id: "Rep", label: "Rep", minWidth: 120, } 
  ];
  const columns = auth.currentUser.uid==="OdATi23YK3QB0HqgOXjkZ7PFWij2" ? x : x.splice(0, x.length-1)
  // Helper Funcs:::::

  const filteredCustomers = (newCustomers) => {
   
    if (search === "") {
      setCustomers(newCustomers);
    } else {
      setCustomers(
        newCustomers.filter(
          (customer) =>
            customer["Name"].toLowerCase().includes(search.toLowerCase()) 
            ||
            customer["Address"]
              .toLowerCase()
              .includes(search.toLowerCase()) 
              ||
            customer["Zipcode"]
              .toLowerCase()
              .includes(search.toLowerCase()) 
            ||
            customer["Mobile phone"]
              .toLowerCase()
              .includes(search.toLowerCase()) 
            ||
            customer["City"]
              .toLowerCase()
              .includes(search.toLowerCase())
        )
      );
    }
  };

  const sortCustomers = (newCustomers) => {
    let x = newCustomers.sort(function (a, b) {
      if (a["Name"] < b["Name"]) {
        return -1;
      }
      if (a["Name"] > b["Name"]) {
        return 1;
      }
      return 0;
    });
    return x
  }

  const updateSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  ///////::::::://////////

  useEffect(() => {
    /// Ternary Condition::::
    const unsubscribe = auth.currentUser.uid==="OdATi23YK3QB0HqgOXjkZ7PFWij2" 
    ? 
    //// Condition True Block (Admin user)::::
    store.collection('clientes')
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let newCustomers=[]
          snapshot.forEach((doc) => {
            // console.log(doc.id)
            newCustomers.push({
              id: doc.id,
              ...doc.data()
            })
         })
         console.log(newCustomers)
        setFetchData(sortCustomers(newCustomers));
        } else {
          console.log("query empty")
        }
      })
    : 
    //// Condition False Block (Regular user)::::
    store.collection('clientes')
    .where("Rep", "==", auth.currentUser.email)
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let newCustomers=[]
          snapshot.forEach((doc) => {
            // console.log(doc.id)
            newCustomers.push({
              id: doc.id,
              ...doc.data()
            })
         })
        setFetchData(sortCustomers(newCustomers));
        } else {
          console.log("query empty")
        }
      })
  return () => {
      unsubscribe()
    }
  }, [store])


  useEffect(() => filteredCustomers(fetchData), [search, fetchData]);

  return (
    <Paper className={classes.root}>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>

        <InputBase
          placeholder="Customer Search"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
          onChange={updateSearch}
        />
      </div>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {customers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((customer) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`${customer["id"]}`}
                  >
                    {columns.map((column) => {
                      const value = customer[column.id];

                      return (
                        <TableCell
                          key={`${column.id}`}
                          align={column.align}
                        >
                        
                          {column.id==="Name" ?  
                      <span>
                         <Link
                              to={{
                                pathname: `newcheckin/${customer.id}`,
                                // state: {
                                //   customer: customer,
                                // },
                              }}
                              className={classes.link}
                            >
                          <AddToPhotosIcon></AddToPhotosIcon>
                          </Link>
                              
                               {value}
                         
                         
                      </span>
                        : 
                        column.id==="Address" ? 
                        
                        
                                  <Link
                                  to={{
                                    pathname: `customer/${customer.id}`,
                                    // state: {
                                    //   customer: customer,
                                    // },
                                  }}
                                  className={classes.link}
                                   >
                                  {`${value} ${customer["City"]}, ${customer["State"]}, ${customer["Zipcode"]} `}
                                  </Link> 
                        
                        : 
                        
                              <Link
                              to={{
                                pathname: `customer/${customer.id}`,
                                // state: {
                                //   customer: customer,
                                // },
                              }}
                              className={classes.link}
                            >
                       {value}
                          </Link> 
                          
                         
                    }
                   


                          
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={customers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
export default withRouter(CustomerList);
