import React from "react"
import { Container } from "react-bootstrap"
import { AuthProvider } from "../contexts/AuthContext"
import { StoreProvider } from "../contexts/StoreContext"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Signup from "./Signup"
import Dashboard from "./Dashboard"
import Login from "./Login"
import PrivateRoute from "./PrivateRoute"
import  AdminRoute  from "./AdminRoute";
import ForgotPassword from "./ForgotPassword"
import UpdateProfile from "./UpdateProfile"
import AdminPanel from './AdminPanel'
import NavBar2 from './NavBar2';
import NewCheckIn from './NewCheckIn'
import MassUpload from './MassUpload'
import CheckInsList from './CheckInsList'
import NewCustomer from './NewCustomer';
import Customer from './Customer';
import EditCustomer from './EditCustomer';
import Checkin from './Checkin';
import DownloadAll from './DownloadAll';
import Forecast from "./Forecast";
import MapWrapper from './MapWrapper'


import CustomerList from './CustomerList'

function App() {
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
         
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <Router>
          <AuthProvider>
          <StoreProvider>
            <NavBar2/>
           
            <Switch>
              <PrivateRoute exact path="/" component={Dashboard} />
              <PrivateRoute path="/updateprofile" component={UpdateProfile} />
              <PrivateRoute path="/customerlist" component={CustomerList} />
              <PrivateRoute path="/forecast" component={Forecast} />
              <PrivateRoute path="/newcustomer" component={NewCustomer} />
              <PrivateRoute path="/newcheckin/:id" component={NewCheckIn} />
              <PrivateRoute path="/customer/:id" component={Customer} />
              <PrivateRoute path="/editcustomer/:id" component={EditCustomer} />
              <PrivateRoute path="/checkin/:id" component={Checkin} />
              <PrivateRoute path="/map" component={MapWrapper} />
              <PrivateRoute path="/checkinslist" component={CheckInsList} />
              <AdminRoute path= "/adminpanel" component={AdminPanel}/>
              <AdminRoute path= "/massupload" component={MassUpload}/>
              <AdminRoute path= "/downloadall" component={DownloadAll}/>
              <Route path="/signup" component={Signup} />
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
            </Switch>
          </StoreProvider>
          </AuthProvider>
        </Router>
      </div>
    </Container>
  )
}

export default App
