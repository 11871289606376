import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useStore } from "../contexts/StoreContext"

const Modalcheckins = (props) => {
    const { getCustomerCheckins } = useStore()
    const customerDocumentId = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1)
    const [fetchData, setFetchData] = useState([]);


    useEffect(() => {
        getCustomerCheckins(customerDocumentId)
         .then((snapshot) => {
              const newCheckIns = snapshot.docs.map((doc) => ({
                firebaseId: doc.id,
                ...doc.data(),
              }));
              const sortedCheckIns = newCheckIns.sort(function (a, b) {
                if (a["dateInput"] > b["dateInput"]) {
                  return -1;
                }
                if (a["dateInput"] < b["dateInput"]) {
                  return 1;
                }
                return 0;
              });
              console.log(sortedCheckIns);
          
             
              setFetchData(sortedCheckIns);
            });
          }, []);





    return (
        <div>
            <Dialog
        open={props.open}
       onClose={props.func}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Check Ins</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {fetchData.length>0 ? fetchData.map((checkin) => {
                return <div style={{border:"1px solid black", marginTop: "5px"}}>
                        <Link
                        to={{
                        pathname: `/checkin/${checkin.firebaseId}`,
                        }}
                        >
                            <div><strong>Date:</strong> {checkin["dateInput"]}</div>
                            <div><strong>Notes:</strong> {checkin["notes"]}</div>
                        </Link>
                        </div>
            }) : 'No Check ins' }
            </DialogContentText>
          {/* <DialogContentText id="alert-dialog-description">
                            <div>
                            Create Check In: {" "}
                            <Link
                              to={{
                                pathname: `newcheckin/${this.state.id}`,
                               
                              }}
                            >
                              
                          <AddToPhotosIcon></AddToPhotosIcon>
                          </Link>
                          </div>
                          <br></br>
                          <br></br>
              
              <div>
                Address: {" "}
                <Link 
                        target="_blank"
                        to={{
                          pathname: `https://www.google.com/maps/dir/Current+Location/${this.state.latitude},${this.state.longitude}`,  
                        }}
                        >
                           {this.state.address}
              </Link>   
              </div>

                          <br></br>
                          <br></br>
                  {this.state.phone ? 
                  
                  <div>
                  Phone: {" "}
                  <Link 
                          target="_blank"
                          to={{
                            pathname: `tel:+1${this.state.phone}`,  
                          }}
                          >
                             {this.state.phone}
                </Link>   
                </div>
                  : null }
                              
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          
         
        </DialogActions>
      </Dialog>
            
        </div>
    );
}

export default Modalcheckins;
