import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useAuth } from "../contexts/AuthContext"
import logo from '../LOCATOR LOGO.png'
import { BiBadge } from "react-icons/bi";


import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  IconButton,
  ListItem,
  ListItemIcon,
  Link,
  Divider,
  Collapse,
  ListItemText,
} from "@material-ui/core";

import { 
  IoIosHome,
  IoMdContacts,
  IoIosFingerPrint,
  IoMdLogOut,
  IoMdSettings,
  IoMdLocate
  } from "react-icons/io";


import { useHistory } from "react-router-dom";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#31579e",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#31579e",
   
   
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  linkText: {
    color: "white",
    textDecoration: "none",
    "&:hover": {
      color: "#8cb1ab",

      textDecoration: "none",
    },
  },

  linkText2: {
    marginTop: "auto",
    bottom: 0,
    color: "white",
    textDecoration: "none",
    "&:hover": {
      color: "#8cb1ab",

      textDecoration: "none",
    },
  },
  navIcon: {
    color: "white",
    // size: "30px",
  
  },
  itemListText: {
    color: "white",
  }, 
  typo: {
    fontFamily: "Inter, Georgia,serif",
    color: "white",
    fontSize: 20
  },
  animation:{
    
    "&:hover": {
      "& $navIcon":{
        // animationDuration:100,
        transform: "rotate(720deg)",
        transition: "transform 1.8s ease-in-out",
        color: "#ebc934"
      }
    
  }
  },
  lang: {
    fontFamily: "Inter, Georgia,serif",
    marginLeft: "auto",
    float: "right",
  },

  navTex: {
    display: "flex",
  },
  bottomPush: {
    position: "fixed",
    bottom: 0,
    textAlign: "center",
    paddingBottom: 10,
    paddingLeft: 30
}
}));


const icons = [
  IoIosHome,
  IoMdContacts,
  IoMdLocate,
  ShowChartIcon,
  IoIosFingerPrint,
  IoMdSettings,
  IoMdLogOut,

];

const titles = {
  0: "Home",
  1: "Customers",
  2: "Check Ins List",
  3: "Forecast",
  4: "Update Profile",
  5: "Admin Panel",
  6: "Logout", 
};

const customerOptions= [
"Customer List",
"New Customer",
"Map"
]




export default function NavBar2() {
  const [openSales, setOpenSales] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory();



  async function handleLogout() {
    setError("")
    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }

  const handleClickSales = () => {
    setOpenSales(!openSales);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };



  const drawer = (
    <div >
      <div className={classes.toolbar} />

      <List className={classes.linkText}>
        {icons.map((Item, index) => {
          return (
           Item===IoMdLogOut ? 
    
        <ListItem key={index + 5} button index={titles[index]} >
          <ListItemIcon key={index + 18}>
            {
              <Item
                key={index + 35}
                className={classes.navIcon}
                size="30px"
              />
            }
          </ListItemIcon>
            <Typography
              key={index + 532}
              className={classes.typo}
              variant="h6"
              noWrap
              onClick={handleLogout}  
            >
              {titles[index]}
          </Typography>
       
         </ListItem> :
              Item=== IoMdContacts? 
             
              <span key={index+Item}>
              <ListItem button onClick={handleClickSales}>
              <ListItemIcon>
                <Item key={index + 35}
                    className={classes.linkText}
                    size="30px"/>
              </ListItemIcon>
              <Typography
                  key={index + 532}
                  className={classes.typo}
                  variant="h5"
                  noWrap
                >
               {titles[index]}
                </Typography>
              {openSales ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openSales} timeout="auto" unmountOnExit>
               {customerOptions.map((d) => {
                     return(
   
                       <List component="div" disablePadding key={d}>
                     <Link 
                       href={`/${d.split(" ").join("").toLowerCase()}`}
                     className={classes.linkText2}>
                       <ListItem button key={d} className={classes.nested}>
                         <ListItemIcon>
                           <BiBadge className={classes.linkText2} />
                         </ListItemIcon>
                         <ListItemText primary={d} />
                       </ListItem>
                     </Link>
   
                   </List>
                     )
               })}
            </Collapse>
              
            </span>

      : 
           <ListItem key={index + 5} button index={titles[index]} 
              className={
                  Item===IoMdSettings &&currentUser ? 
                  currentUser.uid==="OdATi23YK3QB0HqgOXjkZ7PFWij2" ? classes.animation : null 
                   : null}>

           <ListItemIcon key={index + 18}>
             {
               <Item
                 key={index + 35}
                 className={classes.navIcon}
                 size="30px"
               />
             }
           </ListItemIcon>
           <Link
             href={
               titles[index].split(" ").join("").toLowerCase()==="home" ? '/' : 
               `/${titles[index].split(" ").join("").toLowerCase()}` }
             key={titles[index]}
             className={classes.linkText}
             onClick={() => {
               window.scrollTo(0, 0);
             }}
            >
             <Typography
               key={index + 532}
               className={classes.typo}
               variant="h6"
               noWrap
             >
            {titles[index]}
             </Typography>
           </Link>





           
         </ListItem> 
          );
        })}
     {openSales ? null : 
     <div className={classes.bottomPush}>
     <Typography>
       <Link className={classes.linkText2}
       href= "https://luisascencio.com/"
       target="_blank"
       >
       Created by: Luis Ascencio
       </Link>
     </Typography>
 </div>
     
     }
      </List>
    
    </div>
  );

  return (
    <div className={classes.root} >
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div >
            <Link href="/" className={classes.linkText}>
            <img
                  src={logo}
                  style={{ height: "25px",
                marginTop: "5px" }}
                />        
            </Link>
          </div>
          {open === true ? null : (
            <Typography component="div" className={classes.lang}>
            
          
            </Typography>
          )}
   
        </Toolbar>
      </AppBar>
      
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
       
        
      >
        <div className={classes.drawerHeader} >
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon
                style={{
                  color: "white",
                }}
              />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        {drawer}
       
      </Drawer>
      <Divider />
    </div>
  );
}
