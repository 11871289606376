/* eslint-disable no-undef */
import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, InputGroup } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { useStore } from "../contexts/StoreContext"
import { useHistory } from "react-router-dom"
import moment from "moment";


export default function NewCheckIn() {
  const notes = useRef()
  // const selectRef = useRef("Meeting")
  const dateRef = useRef()
  const saleRef = useRef()
  const [selectRef, setSelectRef] = useState("Meeting")
  const {  currentUser } = useAuth()
  const { getSingleCustomer, addCheckIn } = useStore()
  const [error, setError] = useState("")
  const [CurrentLoc, setCurrentLoc] = useState({})
  const [currentAddress, setCurrentAddress] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [customer, setCustomer] = useState("")
  const customerDocumentId = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1)
  const todayDate= moment().format().substring(0,10)
  const typeSelect= [
    "Meeting",
    "Phone Call",
    "Text SMS",
    "Education",
    "Sale",

  ]

  function getReverseGeocodingData(lat, lng) {
    var latlng = new google.maps.LatLng(lat, lng);
  
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'latLng': latlng },  (results, status) =>{
        if (status !== google.maps.GeocoderStatus.OK) {
            alert(status);
        }
        if (status == google.maps.GeocoderStatus.OK) {
            console.log(results);
            var address = (results[0].formatted_address);
            setCurrentAddress(address)
            console.log(currentAddress)
        }
    });
}

const dateFlipper = (date) => {
  
  let year= date.slice(0,4)
  let month = date.slice(5,7)
  let day = date.slice(8, 10)
  let newDate= `${month}/${day}/${year}`
  return newDate
}

const miss = err => {
  console.warn(`ERROR(${err.code}): ${err.message}`);
};

const success = (position) => {
  let pos= position.coords;
getReverseGeocodingData(pos.latitude, pos.longitude)

  // console.log('Your current position is:');
  
  // console.log(`Latitude : ${pos.latitude}`);
  // console.log(`Longitude: ${pos.longitude}`);
  // console.log(`More or less ${pos.accuracy} meters.`);
  setCurrentLoc({
    latitude: pos.latitude,
    longitude: pos.longitude,
  })
  console.log(CurrentLoc)
}



useEffect(() => {
  let options = {
    enableHighAccuracy: true
  };
  navigator.geolocation.getCurrentPosition(success, miss, options);
}, [])

useEffect(() => {
  getSingleCustomer(customerDocumentId)
 .then((snapshot) => {
  const newCustomer = snapshot.data()
  console.log(snapshot.data())
    setCustomer(newCustomer);
    });
  }, []);
  
  function handleChange(e) {
    // console.log(e.currentTarget.value)
    setSelectRef(e.currentTarget.value)
  }


  async function handleSubmit(e) {
    e.preventDefault()
  //  console.log(saleRef.current)
    let date= dateRef.current.value
    let year= parseInt(date.slice(0,4))
    let month = parseInt(date.slice(5,7))
    let day = parseInt(date.slice(8, 10))
    try {
      setError("")
      setLoading(true)

     await addCheckIn(
        currentUser.displayName,
        currentUser.email,
        currentUser.uid,
        customer["Name"],
        customerDocumentId,
        customer["Latitude"],
        customer["Longitude"],
        selectRef,
        saleRef.current ?  parseFloat(saleRef.current.value ):0,
        notes.current.value,
        currentAddress,
        CurrentLoc.latitude,
        CurrentLoc.longitude,
        dateFlipper(date),
        year,
        month,
        day,
        moment().format('MMMM Do YYYY, h:mm:ss a'),
        customer,
      )
      history.push("/checkinslist")
    } catch {
      setError("Failed to create check in")
    }

    setLoading(false)
  }

  return (
    <>
    {console.log(CurrentLoc, currentAddress)}
      <Card style={{ fontFamily: "Inter, Georgia,serif", }}>
        <Card.Body>
          <h2 className="text-center mb-4">Check In</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
          <br></br>
          <Form.Group id="customer">
              <Form.Label>Customer</Form.Label>
              <Form.Control type="text" defaultValue={customer["Name"]} readOnly/>
            </Form.Group>
            <br></br>
            <Form.Group id="rep">
              <Form.Label>Rep</Form.Label>
              <Form.Control type="text" defaultValue={currentUser["displayName"]}  readOnly/>
            </Form.Group>
            <br></br>
            <Form.Group id="date">
              <Form.Label>Date</Form.Label>
              <Form.Control type="date" defaultValue={todayDate}  ref={dateRef}/>
            </Form.Group>

              <br></br>
              <Form.Group id="type">
                <Form.Label>Type</Form.Label>
                <Form.Control type="select" value={selectRef}
                onChange={   (e) => {
                  handleChange(e)
                }}
                  as="select">
             

                    {typeSelect.map((item) => {
                     
                      return <option value={item}>{item}</option>
                    })}
                  
                </Form.Control>
              </Form.Group>
              
                    {selectRef==="Sale" ?
                 <span>
                      <br></br>
                       <InputGroup id="saleamount">
                       {/* <Form.Label>Amount</Form.Label> */}
                   <InputGroup.Text>$</InputGroup.Text>
                       <Form.Control type="number" step=".01"  placeholder="0.00" ref={saleRef}/>
                     </InputGroup>
                 </span>
                    
                    : null}
          



              <br></br>
            <Form.Group id="password-confirm">
              <Form.Label>Notes</Form.Label>
              <Form.Control type="textarea" ref={notes} 
                as="textarea"
                placeholder="Leave a note here"
                style={{ height: '100px' }}
              
              />
            </Form.Group>
            <br></br>
            <Button disabled={loading} className="w-100" type="submit" style={{
              backgroundColor: "#31579e"
            }}>
              Save
            </Button>
          </Form>
        </Card.Body>
      </Card>
     
    </>
  )
}
