import { withRouter, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useStore } from "../contexts/StoreContext"
import moment from "moment";
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useAuth } from "../contexts/AuthContext"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 40,
    width: "95%",
    position: "fixed",
    top: "50%",
    left: "50%",

    transform: "translate(-50%, -50%)",
  },
  root2: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  container: {
    maxHeight: window.innerWidth < 500 ? 440 : 550,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),

    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    container: {
      maxHeight: 440,
    },
  },
  link: {
    margin: theme.spacing.unit,
    color: "black",
    "&:hover": {
      color: "#5dbcd2",

      textDecoration: "none",
    },
  },
}));

const CheckInsList = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const { getCheckIns } = useStore()
  const [select, setSelect] = useState('today');
  const [search, setSearch] = useState("");
  const [checkins, setCheckIns] = useState([]);
  const [fetchData, setFetchData] = useState([]);
  const numberDate= moment().format("L")
  const stringDate= moment().format("LL")
  const year= parseInt(numberDate.slice(6,10))
  const month = parseInt(numberDate.slice(0,2))
  const day = parseInt(numberDate.slice(3, 5))
  const dateStrArr= stringDate.split(" ")
  const {  currentUser } = useAuth()
  const types = [
    {
      value: 'year',
      label: `Check ins on ${dateStrArr[2]}`,
    },
    {
      value: 'month',
      label: `Check ins in ${dateStrArr[0]}`,
    },
    {
      value: 'today',
      label: `Check ins for ${stringDate}`,
    },
  
  ];

  const x = [
    { id: "customerName", label: "Customer", minWidth: 170 },
    { id: "dateInput", label: "Date", minWidth: 170 },
    { id: "type", label: "Check in Type", minWidth: 170 },
    { id: "notes", label: "Notes", minWidth: 170 },
    { id: "Rep", label: "Rep", minWidth: 120, },
    { id: "timestamp", label: "Time Stamp", minWidth: 100, },
  
  ];

  const columns = currentUser.uid==="OdATi23YK3QB0HqgOXjkZ7PFWij2" ? x : x.splice(0, x.length-2) 



  const handleChange = (event) => {
    setSelect(event.target.value);
  };

  const filteredCheckIns = (newCheckIns) => {
   
    if (search === "") {
      setCheckIns(newCheckIns);
    } else {
      setCheckIns(
        newCheckIns.filter(
          (checkin) =>
            checkin["customerName"].toLowerCase().includes(search.toLowerCase()) 
            ||
            checkin["dateInput"]
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            checkin["type"]
              .toLowerCase()
              .includes(search.toLowerCase()) 
        )
      );
    }
  };





  useEffect(() => {
console.log("year", year, 
"month", month, 
"day", day, 
"numberdate", numberDate,
"stringdate",
"dateStrArr", dateStrArr,
stringDate)


    getCheckIns(year, month, day, select)
 .then((snapshot) => {
      const newCheckIns = snapshot.docs.map((doc) => ({
        firebaseId: doc.id,
        ...doc.data(),
      }));
      const sortedCheckIns = newCheckIns.sort(function (a, b) {
        if (a["dateInput"] > b["dateInput"]) {
          return -1;
        }
        if (a["dateInput"] < b["dateInput"]) {
          return 1;
        }
        return 0;
      });
      console.log(sortedCheckIns);
  
     
      setFetchData(sortedCheckIns);
    });
  }, [select]);

  useEffect(() => filteredCheckIns(fetchData), [search, fetchData]);

  //// helper funcs::::

  const updateSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //////////////////////
  console.log("check ins", checkins)
  return (
    <Paper className={classes.root}>
      <div className={classes.search}>
        {/* <div className={classes.searchIcon}>
          <SearchIcon />
        </div> */}
      
        <div >
          <TextField
          size= "small"
             variant="outlined"
            placeholder="Check in Search"
          
            
            onChange={updateSearch}
          style={{paddingLeft: 7,
            paddingTop: 10
           }}
            startAdornment={
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            }
          />
             <TextField
             style={{paddingLeft: 7,
              paddingTop: 10
           }}
            id="filter"
            size= "small"
            variant="outlined"
            select
            value={select}
            onChange={handleChange}>
            {types.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
             </TextField>
            
        </div>
      </div>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {checkins
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((checkin) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`${(columns.id, checkin.firebaseId)}`}
                  >
                    {columns.map((column) => {
                      const value = checkin[column.id];

                      return (
                        <TableCell
                          key={`${(checkin.firebaseId, column.id)}`}
                          align={column.align}
                        >
                        
                          {
                            <Link
                              to={{
                                pathname: `checkin/${checkin.firebaseId}`,
                                // state: {
                                //   customer: customer,
                                // },
                              }}
                              className={classes.link}
                            >
                        {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                              </Link>
                    }

                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={checkins.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
export default withRouter(CheckInsList);
