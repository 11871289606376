import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { useStore } from "../contexts/StoreContext"
import { useHistory } from "react-router-dom"
import moment from "moment";
import axios from 'axios';

export default function EditCustomer(props) {
  const street = useRef()
  const city = useRef()
  const zipCode = useRef()
  const email = useRef()
  const mobilePhone = useRef()
  const name = useRef()
  const nationality = useRef()
  const numberOfChairs = useRef()
  const phone = useRef()
  const { currentUser } = useAuth()
  const { updateCustomer } = useStore()
  const [error, setError] = useState("")
  const [CurrentLoc, setCurrentLoc] = useState({})
  const [currentAddress, setCurrentAddress] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [zipCodeVal, setzipCodeVal] = useState(true)
  const [phoneVal, setphoneVal] = useState(true)
  const [mobilVal, setmobilVal] = useState(true)
  const [rentOrWork, setRentOrWork] = useState(props.location.state["Rent Chair or Work Home"])
  const [salonOwner, setSalonOwner] = useState(props.location.state["Salon Owner"])
  const [state, setState] = useState(props.location.state["State"])
  const rentSelect= ["Rent", "Work from Home"]

  const salonOwnerSelect= ["No","Yes"]
  const statesSelect= ['Alabama','Alaska','American Samoa',
  'Arizona','Arkansas','California',
  'Colorado','Connecticut','Delaware',
  'District of Columbia','Federated States of Micronesia',
  'Florida','Georgia','Guam',
  'Hawaii','Idaho','Illinois',
  'Indiana','Iowa','Kansas',
  'Kentucky','Louisiana','Maine',
  'Marshall Islands','Maryland','Massachusetts',
  'Michigan','Minnesota','Mississippi','Missouri',
  'Montana','Nebraska','Nevada','New Hampshire',
  'New Jersey','New Mexico','New York','North Carolina',
  'North Dakota','Northern Mariana Islands','Ohio','Oklahoma',
  'Oregon','Palau','Pennsylvania','Puerto Rico',
  'Rhode Island','South Carolina','South Dakota','Tennessee',
  'Texas','Utah','Vermont','Virgin Island','Virginia','Washington',
  'West Virginia','Wisconsin','Wyoming']
  const docCustomerId= `${window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1)}`

    console.log("pathname", docCustomerId)
       
    function isUSAZipCode(str) 
    {
      return /^\d{5}(-\d{4})?$/.test(str);
    }
  
  
    function isPhoneNum(str) 
    {
      return /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/.test(str);
    }




  function geocode() {
    let st= street.current.value
    let ci= city.current.value
    let zip = zipCode.current.value
    let location = street.current.value+" " + city.current.value+ " " +  state 
    let fullAdd = street.current.value+" " + city.current.value+ " " +  state + " "+ zipCode.current.value
    console.log(name.current.value)
    let localName= name.current.value
    let localEmail = email.current.value
    let localMobile= mobilePhone.current.value.toString()
    let localNat = nationality.current.value
    let localNumber= numberOfChairs.current.value
    let localPhone = phone.current.value.toString()
    axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        address: location,
        key: process.env.REACT_APP_FIREBASE_API_KEY,
       
      }
    })
      .then(
        function(results) {
          if (results.data.status === 'OK') {
            updateCustomer(
              st,
              ci,
              state,
              zip,
              docCustomerId,
              localEmail,
              results.data.results[0]["geometry"]["location"]["lat"],
              results.data.results[0]["geometry"]["location"]["lng"],
              localMobile,
              localName,
              localNat,
              localNumber,
              localPhone,
              rentOrWork,
              currentUser.email,
              salonOwner,

            )
          } else {
            alert('Geocode was not successful for the following reason: ' + results.data.status);
          }
        }  
      )
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleChange(e) {
    // console.log(e.currentTarget.value)
    setRentOrWork(e.currentTarget.value)
  }


  function handleChange2(e) {
    // console.log(e.currentTarget.value)
    setSalonOwner(e.currentTarget.value)
  }
  function handleChange3(e) {
    // console.log(e.currentTarget.value)
    setState(e.currentTarget.value)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    // let date= dateRef.current.value
    // let year= parseInt(date.slice(0,4))
    // let month = parseInt(date.slice(5,7))
    // let day = parseInt(date.slice(8, 10))
    try {
      setError("")
      setLoading(true)
      geocode()
      history.push("/customerlist")
     
    } catch {
      setError("Failed to create Edit customer")
    }

    setLoading(false)
  }

  return (
    <>
    {console.log(CurrentLoc, currentAddress)}
      <Card style={{ fontFamily: "Inter, Georgia,serif" }}>
        <Card.Body>
          <h2 className="text-center mb-4">Edit customer</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form 
          onSubmit={handleSubmit}
          >
    
          <Form.Group id="name">
              <Form.Label>Customer Name</Form.Label>
              <Form.Control type="text" ref={name} defaultValue={props.location.state["Name"]} required/>
            </Form.Group>

            
      <div className="form-group row">
        
             <div className="col-sm-6">
                <Form.Group id="street">
                  <Form.Label>Street</Form.Label>
                  <Form.Control type="text" ref={street} defaultValue={props.location.state["Address"]} required/>
                </Form.Group>
             </div>
        
            <div className="col-sm-6">
                <Form.Group id="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control type="text" ref={city}  defaultValue={props.location.state["City"]} required/>
                </Form.Group>
            </div>
      </div>

        <div className="form-group row">
          
        <div className="col-sm-6">
                <Form.Group id="zipcode">
                  <Form.Label>Zipcode</Form.Label>
                  <Form.Control type="text" ref={zipCode} required 
                  defaultValue={props.location.state["Zipcode"]}
                  onChange={() => {
                   isUSAZipCode(zipCode.current.value) ? 
                   setzipCodeVal(true) : setzipCodeVal(false)
                  }}
                  style={{border:zipCodeVal ? null : "1.5px solid red" }}
                  
                  />
             
                </Form.Group>
            </div>
            <div className="col-sm-6">
                  <Form.Group id="state">
                    <Form.Label>State</Form.Label>
                    <Form.Control type="select" value={state}
                    onChange={   (e) => {
                      handleChange3(e)
                    }}
                      as="select">
                        {statesSelect.map((item) => {          
                          return <option value={item}>{item}</option>
                        })}
                      
                    </Form.Control>
                  </Form.Group>
               </div>
        </div>
      
        <div className="form-group row">
              <div className="col-sm-6">
                <Form.Group id="phone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="text" ref={phone} 
                  onChange={() => {
                    isPhoneNum(phone.current.value) ? 
                    setphoneVal(true) : setphoneVal(false)
                   }}
                   style={{border:phoneVal ? null : "1.5px solid red" }}
                   defaultValue={props.location.state["Phone"]}
                  />
                </Form.Group>
              </div>
           
             <div className="col-sm-6">
                <Form.Group id="mobilephone">
                  <Form.Label>Mobile Phone</Form.Label>
                  <Form.Control type="text" ref={mobilePhone} 
                        onChange={() => {
                    isPhoneNum(mobilePhone.current.value) ? 
                    setmobilVal(true) : setmobilVal(false)
                   }}
                   style={{border:mobilVal ? null : "1.5px solid red" }}
                   defaultValue={props.location.state["Mobile phone"]}
                  />
                </Form.Group>
             </div>
         </div>
      
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" ref={email} defaultValue={props.location.state["Email"]}/>
            </Form.Group>
      
       <div className="form-group row">
             <div className="col-sm-6">
                <Form.Group id="nationality">
                  <Form.Label>Nationality</Form.Label>
                  <Form.Control type="text" ref={nationality}  defaultValue={props.location.state["Nationality"]}/>
                </Form.Group>
             </div>
         
             <div className="col-sm-6">
                <Form.Group id="numberofchairs">
                  <Form.Label>Number Of Chairs</Form.Label>
                  <Form.Control type="number" ref={numberOfChairs} defaultValue={props.location.state["Number of Chairs"]}/>
                </Form.Group>
             </div>
       </div>
            <br></br>


            <div className="form-group row">
               <div className="col-sm-6">
                  <Form.Group id="rent">
                    <Form.Label>Rent or Work from Home</Form.Label>
                    <Form.Control type="select" value={rentOrWork}
                    onChange={   (e) => {
                      handleChange(e)
                    }}
                      as="select">
                        {rentSelect.map((item) => {          
                          return <option value={item}>{item}</option>
                        })}
                      
                    </Form.Control>
                  </Form.Group>
               </div>
              
               <div className="col-sm-6">
                  <Form.Group id="owner">
                    <Form.Label>Salon Owner</Form.Label>
                    <Form.Control type="select" value={salonOwner}
                    onChange={   (e) => {
                      handleChange2(e)
                    }}
                      as="select">
                        {salonOwnerSelect.map((item) => {          
                          return <option value={item}>{item}</option>
                        })}
                      
                    </Form.Control>
                  </Form.Group>
               </div>
              
            </div>


            <br></br>
            <Button disabled={loading} className="w-100" type="submit" style={{
              backgroundColor: "#31579e"
            }}
            
            >
           
              Save
            </Button>
          </Form>
        </Card.Body>
      </Card>
     {/* <Button onClick={geocode}></Button> */}
    </>
  )
}

