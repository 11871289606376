import moment from "moment"
import React, { useContext, useState, useEffect } from "react"
import { auth, store } from "../firebase"

const StoreContext = React.createContext()

export function useStore() {
  return useContext(StoreContext)
}

export function StoreProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  /// Admin Functions:::::::

  function customerMassUpload(fileArray) {
     const batch = store.batch();
     
    fileArray.map((record, i) => {
      // debugger
      const recordRef = store
        .collection(`clientes`)
        .doc(
          `${record["id"]}`
          // `${i}` +
          //   `${Math.floor(Math.random() * 100)}` +
          //   `${Math.floor(Math.random() * 100)}`
        );

      batch.set(recordRef, record);
    });
    batch.commit().then(() => {
      console.log("finished products");
    });
  }

  function arrayBatch(bigArray) {
    let size = 499; let arrayOfArrays = [];
    for (let i=0; i<bigArray.length; i+=size) {
        arrayOfArrays.push(bigArray.slice(i,i+size));
      }
      console.log(arrayOfArrays); 
      arrayOfArrays.map((arr) => {
          customerMassUpload(arr)
      })
  }

  ///////

  //// Customer Functions ::: 

  async function addCustomer(
  street,
  city,
  state,
  zipcode,
  customerId,
  email,
  latitude,
  longitude,
  mobilePhone,
  name,
  nationality,
  numberOfChairs,
  phone,
  rentChairOrWorkHome,
  rep,
  salonOwner ) 
  {
    store.collection("clientes").add({
    "Address": street,
    "City": city,
    "State": state,
    "Zipcode": zipcode,
    "Customer ID": customerId,
    "Email": email,
    "Latitude": latitude,
    "Longitude": longitude,
    "Mobile phone": mobilePhone,
    "Name": name,
    "Nationality": nationality,
    "Number of Chairs": numberOfChairs,
    "Phone": phone,
    "Rent Chair or Work Home": rentChairOrWorkHome,
    "Rep": rep,
    "Salon Owner": salonOwner,
    "TimeStamp": moment().format('MMMM Do YYYY, h:mm:ss a')
    })
  }




  async function updateCustomer(
    street,
    city,
    state,
    zipcode,
    docCustomerId,
    email,
    latitude,
    longitude,
    mobilePhone,
    name,
    nationality,
    numberOfChairs,
    phone,
    rentChairOrWorkHome,
    rep,
    salonOwner ) 
    {
      store.collection("clientes").doc(docCustomerId).update({
      "Address": street,
      "City": city,
      "State": state,
      "Zipcode": zipcode,
      "Email": email,
      "Latitude": latitude,
      "Longitude": longitude,
      "Mobile phone": mobilePhone,
      "Name": name,
      "Nationality": nationality,
      "Number of Chairs": numberOfChairs,
      "Phone": phone,
      "Rent Chair or Work Home": rentChairOrWorkHome,
      "Rep": rep,
      "Salon Owner": salonOwner,
      "TimeStamp": moment().format('MMMM Do YYYY, h:mm:ss a')
      })
    }













  async function getCustomers() {
    if (auth.currentUser.uid==="OdATi23YK3QB0HqgOXjkZ7PFWij2") {
      const newCustomers= await store.collection("clientes").get();
        return newCustomers
    } else {
      const filteredCustomers = await store.collection("clientes")
        .where("Rep", "==", auth.currentUser.email)
        .get()
          return filteredCustomers
    }
  }
 
  async function getSingleCustomer(docId) {
    
    let docRef = store.collection("clientes").doc(`${docId}`);
      const result = await docRef.get()
              return result
  }

/// Check ins Functions:::

    async function getCustomerCheckins(customer) {
     
        const filteredCheckIns = await store.collection("checkins")
          .where("customerDocumentId", "==", customer)
          .get()
            return filteredCheckIns
      
    }




  function addCheckIn(
  rep, 
  repEmail, 
  repId,
  customerName, 
  customerDocumentId, 
  customerLatitude,
  customerLongitude,
  type,
  saleAmount, 
  notes, 
  currentLocation, 
  currentLatitude, 
  currentLongitude,
  dateInput, 
  dateInputyear,
  dateInputmonth,
  dateInputday,
  timestamp, 
  customer, 
   ) {

store.collection("checkins").add({
  rep, 
  repEmail, 
  repId,
  customerName, 
  customerDocumentId,
  customerLatitude,
  customerLongitude, 
  type,
  saleAmount, 
  notes, 
  currentLocation,
  currentLatitude, 
  currentLongitude,
  dateInput,
  dateInputyear,
  dateInputmonth,
  dateInputday,
  timestamp,
  ...customer,
  
})
 
  }

  async function getCheckIns(year, month, day, select) {
    


      switch (select) {
        case "today":
            
          if (auth.currentUser.uid==="OdATi23YK3QB0HqgOXjkZ7PFWij2") {
            const newCheckIns= await store.collection("checkins").where("dateInputday", "==", day)
            .where("dateInputmonth", "==", month).where("dateInputyear", "==", year).get()
              return newCheckIns
        
          } else {
            const newCheckIns = await store.collection("checkins")
              .where("Rep", "==", auth.currentUser.email).where("dateInputday", "==", day)
              .where("dateInputmonth", "==", month).where("dateInputyear", "==", year)
              .get()
                return newCheckIns
          }
          break;


        case "month":

          if (auth.currentUser.uid==="OdATi23YK3QB0HqgOXjkZ7PFWij2") {
            const newCheckIns= await store.collection("checkins").where("dateInputmonth", "==", month)
            .where("dateInputyear", "==", year).get()
              return newCheckIns
        
          } else {
            const newCheckIns = await store.collection("checkins")
              .where("Rep", "==", auth.currentUser.email).where("dateInputmonth", "==", month)
              .where("dateInputyear", "==", year).get()
                return newCheckIns
          }
          break;
        
        case "year":

          if (auth.currentUser.uid==="OdATi23YK3QB0HqgOXjkZ7PFWij2") {
            const newCheckIns= await store.collection("checkins").where("dateInputyear", "==", year).get()
              return newCheckIns
        
          } else {
            const newCheckIns = await store.collection("checkins")
              .where("Rep", "==", auth.currentUser.email).where("dateInputyear", "==", year).get()
                return newCheckIns
          }
          break;



        default:
          break;
      }

















  
  }

  async function getSingleCheckin(docId) {
    
    let docRef = store.collection("checkins").doc(`${docId}`);
      const result = await docRef.get()
              return result
  }

  async function getMonthlySales(month, year) {
    const newCheckIns = await store.collection("checkins")
        .where("Rep", "==", auth.currentUser.email).where("dateInputmonth", "==", month)
        .where("dateInputyear", "==", year).where("saleAmount", ">", 0).get()
          return newCheckIns
  }




  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    arrayBatch,
    getCustomers,
    getSingleCustomer,
    addCheckIn,
    getCheckIns,
    addCustomer,
    getSingleCheckin,
    getMonthlySales, 
    updateCustomer,
    getCustomerCheckins
   
   
      
  }

  return (
    <StoreContext.Provider value={value}>
      {!loading && children}
    </StoreContext.Provider>
  )
}

