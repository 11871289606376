import React from 'react';
import MassUpload from './MassUpload';

const AdminPanel = () => {
    return (
        <div>
            <h1>Admin Panel</h1>
        </div>
    );
}

export default AdminPanel;
