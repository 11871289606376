import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {  Link } from "react-router-dom"
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';

class Map extends Component {
  
  constructor() {
    super();
    this.state = {
      open: false,
      name: "",
      address: "",
      latitude: 0,
      longitude: 0,
      id: "",
      phone: ""
      
    };
  }

      handleClickOpen = (customer) => {

        

        this.setState({open: true,
          name: customer["Name"],
          address: `${customer["Address"]} ${customer["City"]}, ${customer["State"]}, ${customer["Zipcode"]}`,
          longitude: customer["Longitude"],
          latitude: customer["Latitude"],
          id: customer["id"],
          phone: customer["Phone"]
                      });
                                      }

      handleClickClose = () => {
        this.setState({open: false});
      }









  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: this.props.size.height, 
        width: this.props.size.width,
        position: "fixed",
        top: "50%",
        left: "50%",
        zIndex:1,
        transform: "translate(-50%, -50%)"
      }}>

        <Dialog
        open={this.state.open}
        onClose={this.handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{this.state.name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
                            <div>
                            Create Check In: {" "}
                            <Link
                              to={{
                                pathname: `newcheckin/${this.state.id}`,
                               
                              }}
                            >
                              
                          <AddToPhotosIcon></AddToPhotosIcon>
                          </Link>
                          </div>
                          <br></br>
                          <br></br>
              
              <div>
                Address: {" "}
                <Link 
                        target="_blank"
                        to={{
                          pathname: `https://www.google.com/maps/dir/Current+Location/${this.state.latitude},${this.state.longitude}`,  
                        }}
                        >
                           {this.state.address}
              </Link>   
              </div>

                          <br></br>
                          <br></br>
                  {this.state.phone ? 
                  
                  <div>
                  Phone: {" "}
                  <Link 
                          target="_blank"
                          to={{
                            pathname: `tel:+1${this.state.phone}`,  
                          }}
                          >
                             {this.state.phone}
                </Link>   
                </div>
                  : null }
                              
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClickClose} color="primary">
            Close
          </Button>
         
        </DialogActions>
      </Dialog>
        <GoogleMapReact
          defaultCenter={{lat: this.props.location.latitude, lng: this.props.location.longitude}}
          defaultZoom={12}
        >

{this.props.customers.map(  (customer) => {
  return (
     
     <PersonPinCircleIcon
     title={customer["Name"]}
     Name={"test"}
     position={{lat:customer["Latitude"], lng:customer["Longitude"] }}
     lat={customer["Latitude"]}
     lng={customer["Longitude"]}
     text={customer["Name"]}
     style={{color:"red"}}
     onClick={  () => {
        this.handleClickOpen(customer)
     }}
   />
  )
})}
        </GoogleMapReact>
      </div>
    );
  }
}
 
export default Map;